import React, { useState } from 'react';
import Layout from '../../components/layout';
import { AltWrapper, Title } from '../../styles/commonStyles';
import { sendAuthenticationEmail } from '../../async/emergencyAccess/authenticateEmail';
import EmergencyEmail from '../../atoms/emergencyEmail';
import { validation } from '../../services/validation';
import { navigate } from 'gatsby';
import { paths } from '../../const/paths';

const EmergencyAccess: React.FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const onClick = async () => {
    const error = validation.email(email);
    if (error) {
      return setError(error);
    }
    await sendAuthenticationEmail(email);
    navigate(paths.emailSent, { state: { email } });
  };

  return (
    <Layout>
      <AltWrapper>
        <div>
          <Title>Need emergency account access?</Title>
          <EmergencyEmail
            onClick={onClick}
            onChangeText={setEmail}
            error={error}
          />
          <p>
            This must be the same email address you use for your Bó account. You
            should receive an email containing a login link. Tap the link in the
            email to access emergency account details.
          </p>
        </div>
      </AltWrapper>
    </Layout>
  );
};

export default EmergencyAccess;
