import React from 'react';
import { Wrapper, Input } from './styles';

export type TextInputProps = {
  placeholder: string;
  onChangeText: (input: string) => void;
  error?: string;
  width?: string;
};

const TextInput = ({
  placeholder,
  onChangeText,
  error,
  width,
}: TextInputProps) => {
  const isError = error !== '';

  return (
    <Wrapper width={width}>
      <Input
        placeholder={placeholder}
        isError={isError}
        type="email"
        aria-label="email"
        name="EMAIL"
        id="mce-EMAIL"
        onChange={(event) => onChangeText(event.target.value)}
      />
    </Wrapper>
  );
};

export default TextInput;
