import React from 'react';
import TextInput from '../textInput';
import Button from '../button';
import { Wrapper } from './styles';

export type EmergencyEmailProps = {
  onChangeText: (text: string) => void;
  onClick: () => void;
  error: string;
};

const EmergencyEmail = ({
  onChangeText,
  onClick,
  error,
}: EmergencyEmailProps) => {
  return (
    <Wrapper>
      <TextInput
        placeholder="email"
        onChangeText={onChangeText}
        error={error}
        width="70%"
      />
      <Button title="Submit" onClick={onClick} width="30%" />
    </Wrapper>
  );
};

export default EmergencyEmail;
