import styled from 'styled-components';
import { colors, spacing, fontSizes, media } from '../../styles/theme';

export const Wrapper = styled.button<{
  width?: string;
  color?: string;
  textColor?: string;
  rounded?: boolean;
}>`
  background-color: ${props => (props.color ? props.color : colors.darkGrey)};
  border-bottom-right-radius: ${spacing.borderRadius};
  border-top-right-radius: ${spacing.borderRadius};
  ${props => props.rounded && `border-radius: ${spacing.borderRadius};`}
  border: 1px solid ${props => (props.color ? props.color : colors.darkGrey)};
  font-size: ${fontSizes.buttonMobile};
  height: 50px;
  color: ${props => (props.textColor ? props.textColor : colors.white)};
  width: ${props => (props.width ? props.width : `100%`)};

  &:hover {
    background-color: ${props =>
      props.color ? colors.hoverYellow : colors.black};
    border: 1px solid
      ${props => (props.color ? colors.hoverYellow : colors.black)};
    cursor: pointer;
  }
  ${media.phone`
    font-size: ${fontSizes.normal};
  `}
`;
