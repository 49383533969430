import { messages } from '../const/messages';

export const validation = {
  email(value: string): string | void {
    let error;
    let valid;

    if (value.length === 0) {
      error = messages.emailEmpty;
      return error;
    }
    const regex = /^(([^°•○●□■♤♡◇♧¿¡》《¤▪☆<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = regex.test(value);
    error = !valid && messages.emailError;
    return error && error;
  },
};
