import styled from 'styled-components';
import { media } from '../../styles/theme';

export const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  flex-direction: row;
  ${media.desktop`
    width: 70%;
  `}
`;
