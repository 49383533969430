import React from 'react';
import { Wrapper } from './styles';

export type ButtonProps = {
  title: string;
  onClick?: () => void;
  width?: string;
  color?: string;
  textColor?: string;
  rounded?: boolean;
};

const Button = ({
  title,
  onClick,
  width,
  color,
  textColor,
  rounded,
}: ButtonProps) => (
  <Wrapper
    onClick={onClick}
    color={color}
    textColor={textColor}
    width={width}
    rounded={rounded}
    type="submit"
    aria-label="submit"
    name="subscribe"
    id="mc-embedded-subscribe"
  >
    {title}
  </Wrapper>
);

export default Button;
