import styled from 'styled-components';
import { colors, fontSizes, spacing, media } from '../../styles/theme';

export const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${props => (props.width ? props.width : `100%`)};
  flex-direction: column;
`;

export const Input = styled.input<{ isError: boolean }>`
  font-size: ${fontSizes.buttonMobile};
  font-family: 'GilroySemiBold';
  width: 100%;
  height: 50px;
  padding-left: ${spacing.base};
  border-bottom-left-radius: ${spacing.borderRadius};
  border-top-left-radius: ${spacing.borderRadius};
  color: ${colors.signUpText};
  background-color: ${colors.white};
  border: 1px solid
    ${props => (props.isError ? colors.red : colors.backgroundGrey)};
  border-right: 0px;
  &::-webkit-input-placeholder {
    color: ${colors.signUpText};
  }

  ${media.tablet`
    font-size: ${fontSizes.mobileText};
  `}
`;
